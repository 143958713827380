.cabecalho {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #232323;
    padding: 10px 20px;
    color: #fff;
    position: fixed;
    width: 100vw;
    z-index: 1000;
    top: 0;
    left: 0;
    box-sizing: border-box;
}

.cabecalho-logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.cabecalho-menu ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.cabecalho-menu li {
    margin: 0 10px;
}

.cabecalho-menu a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

.cabecalho-menu a:hover {
    color: #ffa652;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }

    .cabecalho-menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #232323;
        width: 100%;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
        padding: 10px 0;
    }

    .cabecalho-menu.open {
        display: flex;
    }

    .cabecalho-menu ul {
        flex-direction: column;
        text-align: center;
    }

    .cabecalho-menu li {
        margin: 10px 0;
    }
}
