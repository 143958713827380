.footer {
    margin: 0;
    padding: 20px;
    background-color: #000;
    color: #fff;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}
.footer-links ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}


.footer-links li {
    display: inline-block;
}

.footer-links a {
    margin: 0 10px;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    text-decoration: underline;
    color: #FFA652;
}
@media (max-width: 768px) {
    .footer-links ul {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .footer-links li {
        width: 100%;
        text-align: center;
    }
}
