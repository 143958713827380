.styled-hero {
    position: relative;
    background-color: #232323;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}

.background-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
}

.image-container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.styled-img {
    width: 75%;
    max-width: 300px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.text-container {
    position: relative;
    z-index: 2;
    text-align: center;
}

.fade-in {
    color: #ffffff;
    animation: fadeInUp 1s ease-out forwards;
}

.highlight-text {
    color: #ffa652;
    font-weight: bold;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.delay-1 {
    animation-delay: 0.3s;
}

.delay-2 {
    animation-delay: 0.6s;
}

.styled-button {
    margin-top: 20px;
}

.styled-button button {
    background-color: #ffa652 !important;
    color: #fff !important;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.styled-button button:hover {
    background-color: #e59442 !important;
    transform: scale(1.05);
}

.styled-button button svg {
    margin-right: 8px;
    color: #000 !important;
}

.styled-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .styled-hero {
        flex-direction: column;
    }

    .styled-img {
        max-width: 200px;
    }

    .text-container h1 {
        font-size: 2rem;
    }

    .text-container h4 {
        font-size: 1.5rem;
    }

    .text-container h5 {
        font-size: 1rem;
    }
}

