.servicos {
    padding: 40px 20px;
    text-align: center;
    background-color: #232323;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    margin: 0;

}

.titulo-secao {
    font-size: 2.5em;
    color: #FFA652;
    margin-bottom: 30px;
    font-weight: 700;
}

.descricao-secao1 {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #dddddd;
}

.servicos-lista {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.servico-card {
    background-color: #1f1f1f;
    max-width: 350px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: #ffffff;
    margin-bottom: 15px;
    display: block;
}

.servico-card h3 {
    font-size: 1.6em;
    color: #FFA652;
    margin-bottom: 15px;
    font-weight: 600;
}

.servico-card p {
    color: #dddddd;
    font-size: 1em;
    line-height: 1.6;
}

.chamada-acao {
    font-size: 1.4em;
    color: #FFA652;
    margin-top: 40px;
    font-weight: bold;
    text-align: center;
}

.servico-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
}

.servico-cta {
    margin-top: 30px;
}

.servico-cta button {
    background-color: #FFA652;
    color: #1f1f1f;
    font-size: 1em;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.servico-cta button:hover {
    background-color: #ffffff;
    color: #232323;
}
