.projetos {
    margin: 0;
    padding: 40px 20px;
    background-color: #232323;
    color: #ffffff;
    text-align: center;
}

.titulo-secao {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ffa652;
    font-weight: bold;
}

.descricao-secao {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #dddddd;
}

.projetos-lista {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.projeto-card {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 8px;
    max-width: 300px;
    width: 100%;
    text-align: left;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.projeto-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

.projeto-titulo {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #ffa652;
}

.projeto-descricao {
    font-size: 1em;
    margin-bottom: 20px;
    color: #ffffff;
}

.projeto-botao {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffa652;
    color: #000;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.projeto-botao:hover {
    background-color: #ff8c42;
    transform: scale(1.1);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #232323;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    color: #fff;
    text-align: center;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #232323;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 600px;
    width: 90%;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

.modal-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    position: relative;
}

.modal-image {
    max-width: 100%;
    max-height: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.arrow {
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    position: relative;
    z-index: 10;
    transition: transform 0.2s ease;
}

.arrow:hover {
    transform: scale(1.2);
}

.left-arrow {
    position: absolute;
    left: -40px;
}

.right-arrow {
    position: absolute;
    right: -40px;
}

@media (max-width: 768px) {
    .projetos-lista {
        flex-direction: column;
        align-items: center;
    }
}
