.contato {
    padding: 40px 20px;
    text-align: center;
    background-color: #232323;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    margin: 0;
}

.titulo-secao {
    font-size: 2.5em;
    color: #FFA652;
    margin-bottom: 30px;
    font-weight: 700;
}

.contato p {
    color: #ddd;
    font-size: 1.2em;
    margin-bottom: 40px;
}

.contato-lista {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.contato-item {
    display: flex;
    align-items: center;
    color: #ddd;
    font-size: 1.2em;
    text-decoration: none;
    padding: 10px 20px;
    background-color: #1f1f1f;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, color 0.3s ease;
}

.contato-item:hover {
    transform: translateY(-5px);
    color: #FFA652;
}

.contato-icon {
    font-size: 1.5em;
    margin-right: 8px;
    color: #FFA652;
}
